import './vendor/bootstrap'
import 'unpoly/dist/unpoly'
import Cleave from 'cleave.js';

require('cleave.js/dist/addons/cleave-phone.ch');
import hyperform from 'hyperform'

up.compiler('body', (element) => {
    $('html').removeClass('no-js')

    const $body = element;
    let scrollPosition = 0;

    window.disableScroll = () => {
        scrollPosition = window.pageYOffset;
        $body.style.overflow = 'scroll';
        $body.style.position = 'fixed';
        $body.style.top = `-${scrollPosition}px`;
        $body.style.width = '100%';
    };

    window.enableScroll = () => {
        $body.style.removeProperty('overflow');
        $body.style.removeProperty('position');
        $body.style.removeProperty('top');
        $body.style.removeProperty('width');
        window.scrollTo(0, scrollPosition);
    };

    if (window.matchMedia('(any-hover: hover)').matches) {
        $('body')
            .on('mouseenter mouseleave', '.dropdown', toggleDropdown)
            .on('click', '.dropdown-menu a', toggleDropdown);

        return () => {
            $('body').off('mouseenter mouseleave').off('click')
        }
    }

    $('.dropdown-menu').on("click.bs.dropdown", function (e) {
        e.stopPropagation();
    });
})

up.compiler('.js-show-info-modal', () => {
    $('[data-toggle="modal"]').trigger('click')
});

up.compiler('.page-index .js-talent-form', (element) => {
    up.observe(element, {batch: true}, function (diff) {
        if (diff['talent_form[skills][]']) {
            $('.js-talent-form-submit').removeClass('opacity-0').addClass('opacity-1')
            $('.js-talent-form-hint').removeClass('opacity-1').addClass('opacity-0')
        } else {
            $('.js-talent-form-submit').removeClass('opacity-1').addClass('opacity-0')
            $('.js-talent-form-hint').removeClass('opacity-0').addClass('opacity-1')
        }
    })
})

up.compiler('.js-talent-form', (element) => {
    let $skills = $('[name="talent_form[skills][]"]');

    hyperform.addTranslation("de", {
        TextTooLong: "Bitte kürze diesen Text auf maximal %l Zeichen (Du verwendest derzeit %l Zeichen).",
        TextTooShort: "Bitte verwende zumindest %l Zeichen (Du verwendest derzeit %l Zeichen).",
        ValueMissing: "Bitte fülle dieses Feld aus.",
        CheckboxMissing: "Bitte klicke dieses Kästchen an, wenn Du fortfahren möchtest.",
        RadioMissing: "Bitte wähle eine dieser Optionen.",
        FileMissing: "Bitte wähle eine Datei.",
        SelectMissing: "Bitte wähle einen Eintrag in der Liste.",
        InvalidEmail: "Bitte gib eine E-Mail-Adresse ein.",
        InvalidURL: "Bitte gib eine Internetadresse ein.",
        PatternMismatch: "Bitte halte dich an das vorgegebene Format.",
        PatternMismatchWithTitle: "Bitte halte dich an das vorgegebene Format: %l.",
        NumberRangeOverflow: "Bitte wähle einen Wert, der nicht größer ist als %l.",
        DateRangeOverflow: "Bitte wähle einen Wert, der nicht später ist als %l.",
        TimeRangeOverflow: "Bitte wähle einen Wert, der nicht später ist als %l.",
        NumberRangeUnderflow: "Bitte wähle einen Wert, der nicht kleiner ist als %l.",
        DateRangeUnderflow: "Bitte wähle einen Wert, der nicht früher ist als %l.",
        TimeRangeUnderflow: "Bitte wähle einen Wert, der nicht früher ist als %l.",
        StepMismatch: "Bitte wähle einen gültigen Wert. Die zwei nähesten gültigen Werte sind %l und %l.",
        StepMismatchOneValue: "Bitte wähle einen gültigen Wert. Der näheste gültige Wert ist %l.",
        BadInputNumber: "Bitte gib eine Nummer ein.",
        "Please match the requested type.": "Bitte pass die Eingabe dem geforderten Typ an.",
        "Please comply with all requirements.": "Bitte erfülle alle Anforderungen.",
        "Please lengthen this text to %l characters or more (you are currently using %l characters).": "Bitte verlängere diesen Text auf mindestens %l Zeichen (Du verwendest derzeit %l Zeichen).",
        "Please use the appropriate format.": "Bitte verwende das passende Format.",
        "Please enter a comma separated list of email addresses.": "Bitte gib eine komma-getrennte Liste von E-Mail-Adressen an.",
        "Please select a file of the correct type.": "Bitte wähle eine Datei vom korrekten Typ.",
        "Please select one or more files.": "Bitte wähle eine oder mehrere Dateien.",
        "any value": "jeder Wert",
        "any valid value": "jeder gültige Wert",
    });

    hyperform.addTranslation("fr", {
        TextTooLong: "Veuillez raccourcir ce champ à %l caractères ou moins (vous utilisez actuellement %l caractères).",
        TextTooShort: "Veuillez utiliser au moins %l caractères pour ce champ (vous utilisez actuellement %l caractères).",
        ValueMissing: "Veuillez compléter ce champ.",
        CheckboxMissing: "Veuillez cocher cette case si vous désirez poursuivre.",
        RadioMissing: "Veuillez sélectionner l’une de ces options.",
        FileMissing: "Veuillez sélectionner un fichier.",
        SelectMissing: "Veuillez sélectionner un élément de la liste.",
        InvalidEmail: "Veuillez saisir une adresse électronique valide.",
        InvalidURL: "Veuillez saisir une URL.",
        PatternMismatch: "Veuillez modifier la valeur pour correspondre au format demandé.",
        PatternMismatchWithTitle: "Veuillez modifier la valeur du champ pour correspondre au format demandé : %l.",
        NumberRangeOverflow: "Veuillez sélectionner une valeur inférieure ou égale à %l.",
        DateRangeOverflow: "Veuillez sélectionner une valeur antérieure ou égale à %l.",
        TimeRangeOverflow: "Veuillez sélectionner une valeur antérieure ou égale à %l.",
        NumberRangeUnderflow: "Veuillez sélectionner une valeur supérieure ou égale à %l.",
        DateRangeUnderflow: "Veuillez sélectionner une valeur postérieure ou égale à %l.",
        TimeRangeUnderflow: "Veuillez sélectionner une valeur postérieure ou égale à %l.",
        StepMismatch: "Veuillez sélectionner une valeur valide. Les deux valeurs valides les plus proches sont %l et %l.",
        StepMismatchOneValue: "Veuillez sélectionner une valeur valide. La valeur valide la plus proche est %l.",
        BadInputNumber: "Veuillez saisir un nombre.",
        "Please match the requested type.": "Veuillez respecter le type de champ demandé.",
        "Please comply with all requirements.": "Veuillez respecter toutes les conditions.",
        "Please lengthen this text to %l characters or more (you are currently using %l characters).": "Veuillez allonger ce texte pour atteindre %l caractères ou plus (vous avez écrit pour l'instant %l caractères).",
        "Please use the appropriate format.": "Veuillez utiliser le format approprié.",
        "Please enter a comma separated list of email addresses.": "Veuillez saisir une liste d'adresses électroniques séparées par des virgules.",
        "Please select a file of the correct type.": "Veuillez sélectionner un fichier du type approprié.",
        "Please select one or more files.": "Veuillez sélectionner un ou plusieurs fichiers.",
        "any value": "n'importe quelle valeur",
        "any valid value": "n'importe quelle valeur valide",
    });

    hyperform(window);
    hyperform.setLanguage($('html').data('lang'))

    hyperform.addValidator(
        $skills[0],
        function (element) {
            let valid = $('[name="talent_form[skills][]"]:checked').length > 0;
            element.setCustomValidity(
                valid ?
                    '' :
                    $(element).data('missing-value')
            );
            return valid;
        }
    );

    $skills.on('change', () => {
        $skills.first()[0].reportValidity();
    })
})

up.compiler('.js-phone-field', (element) => {
    new Cleave(element, {
        phone: true,
        phoneRegionCode: 'ch'
    });
})

up.compiler('.js-next-button', (element) => {
    $(element).on('click', () => {
        let isValid = true;

        if (!$('[name="talent_form[skills][]"]')[0].reportValidity()) return false;
        $('.test-question input[type="radio"][value="trifft_zu"]').each((idx, input) => {
            isValid = isValid && input.checkValidity();
            input.reportValidity()
        });

        if (isValid) {
            $('.js-step-1').fadeOut(() => {
                $('.js-step-2').fadeIn()
                scrollToTop()
            })
        }
    });

    return () => {
        $(element).off('click')
    }
});

up.compiler('.js-back-button', (element) => {
    $(element).on('click', () => {
        $('.js-step-2').fadeOut(() => {
            $('.js-step-1').fadeIn()
            scrollToTop()
        })
    })

    return () => {
        $(element).off('click')
    }
});

up.compiler('[data-toggle="off-canvas"]', (element) => {
    let $collapse = $('.off-canvas-collapse');

    $(element).on('click', () => {
        $collapse.toggleClass('open');

        if ($collapse.hasClass('open')) {
            disableScroll();
            $(document).one('keydown.off-canvas', function (e) {
                if (e.key === "Escape") {
                    $collapse.toggleClass('open');
                    enableScroll()
                }
            });
        } else {
            enableScroll();
            $(document).off('keydown.off-canvas');
        }
    })
});

up.compiler('.js-close-dropdown', (element) => {
    $(element).on('click', function ($e) {
        $e.preventDefault()
        toggleDropdown($e)
    })
});

up.compiler('.js-toggle-search', (element) => {
    let $search = $('.js-search')

    $(element).on('click', () => {
        $search.fadeToggle(() => {
            if ($search.is(':visible')) {
                $('.js-search input.form-control').focus()
                $(document).one('keydown.toggle-search', function (e) {
                    if (e.key === "Escape") {
                        $search.fadeToggle()
                    }
                });
            } else {
                $(document).off('keydown.toggle-search');
            }
        });
    })

    return () => {
        $(element).off('click')
    }
})

const scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
    }
};

const toggleDropdown = (e) => {
    const _d = $(e.target).closest('.dropdown'),
        _m = $('.dropdown-menu', _d);
    setTimeout(function () {
        const shouldOpen = e.type !== 'click' && _d.is(':hover');
        _m.toggleClass('show', shouldOpen);
        _d.toggleClass('show', shouldOpen);
        $('[data-toggle="dropdown"]', _d).attr('aria-expanded', shouldOpen);
    }, e.type === 'mouseleave' ? 300 : 0);
}

